<i18n src="@/i18n/locales/super-viewer-containers-board.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-containers-board.fr.json"></i18n>
<i18n src="@/i18n/locales/container.en.json"></i18n>
<i18n src="@/i18n/locales/container.fr.json"></i18n>
<i18n src="@/i18n/locales/location.en.json"></i18n>
<i18n src="@/i18n/locales/location.fr.json"></i18n>
<i18n src="@/i18n/locales/contact.en.json"></i18n>
<i18n src="@/i18n/locales/contact.fr.json"></i18n>
<i18n src="@/i18n/locales/event.en.json"></i18n>
<i18n src="@/i18n/locales/event.fr.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-pop-up-actor.en.json"></i18n>
<i18n src="@/i18n/locales/super-viewer-pop-up-actor.fr.json"></i18n>
<i18n src="@/i18n/locales/customer-order.en.json"></i18n>
<i18n src="@/i18n/locales/customer-order.fr.json"></i18n>
<i18n src="@/i18n/locales/voyage-info.en.json"></i18n>
<i18n src="@/i18n/locales/voyage-info.fr.json"></i18n>
<i18n src="@/i18n/locales/goods.en.json"></i18n>
<i18n src="@/i18n/locales/goods.fr.json"></i18n>
<i18n src="@/i18n/locales/goods-info.en.json"></i18n>
<i18n src="@/i18n/locales/goods-info.fr.json"></i18n>
<i18n src="@/i18n/locales/event-info.en.json"></i18n>
<i18n src="@/i18n/locales/event-info.fr.json"></i18n>
<i18n src="@/i18n/locales/comment-info.en.json"></i18n>
<i18n src="@/i18n/locales/comment-info.fr.json"></i18n>
<i18n src="@/i18n/locales/container-info.en.json"></i18n>
<i18n src="@/i18n/locales/container-info.fr.json"></i18n>
<i18n src="@/i18n/locales/hazardous-class.en.json"></i18n>
<i18n src="@/i18n/locales/hazardous-class.fr.json"></i18n>
<i18n src="@/i18n/locales/my-locations.en.json"></i18n>
<i18n src="@/i18n/locales/my-locations.fr.json"></i18n>
<i18n src="@/i18n/locales/history-details.en.json"></i18n>
<i18n src="@/i18n/locales/history-details.fr.json"></i18n>

<template>
  <li v-if="!isDuplicateData">
    <div :class="itemClasses">
      <div class="label" v-if="node.type != 'primitive' && !isNumeric(label)">
        <strong>{{ $t(label) }}</strong>
      </div>
      <div class="type-wrapper">
        <b-tag
          :icon="iconName"
          v-if="node.children.length > 0 && node.action"
          :type="tagType"
        >
          {{ $t(node.action) }}
        </b-tag>
      </div>

      <div class="item-wrapper" v-if="node.type == 'primitive'">
        <div
          :class="{ label: label === 'number' }"
          class="has-text-centered-mobile"
          v-if="canShowLabel"
        >
          <strong>{{ $t(label) }}</strong>
        </div>
        <div class="mt-1 mb-3 has-text-centered-mobile">
          <!-- Value display for Edited, Added, or Removed types -->
          <div class="edit-wrapper">
            <div v-if="node.oldValue">
              <div v-if="isNotFilename" data-testid="old-value">
                {{ formatValue(node.oldValue) }}
              </div>
              <div v-else class="image-container">
                <img
                  :src="oldImage"
                  v-if="oldImage"
                  alt="Old value image"
                  class="picture-content"
                />
                <img
                  :src="spinner"
                  v-if="isLoadingOldImage"
                  :width="80"
                  :height="80"
                />
              </div>
            </div>
            <div v-if="isEdited">
              <b-icon
                pack="fas"
                icon="arrow-right"
                class="is-hidden-mobile ml-2 mr-2"
              />
              <b-icon
                pack="fas"
                icon="arrow-down"
                class="is-hidden-tablet mt-2 mb-2"
              />
            </div>
            <div>
              <div v-if="isNotFilename" data-testid="new-value">
                {{ formatValue(node.newValue) }}
              </div>
              <div v-else class="image-container">
                <img
                  :src="newImage"
                  v-if="newImage"
                  alt="New value image"
                  class="picture-content"
                />
                <img
                  :src="spinner"
                  v-if="isLoadingNewImage"
                  :width="80"
                  :height="80"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Recursive call for child nodes -->
      <ul v-if="node.children && node.children.length">
        <HistoryDetailsNode
          v-for="(item, index) in node.children"
          :key="index"
          :node="item"
        />
      </ul>
    </div>
  </li>
</template>

<script>
import moment from "moment";
import ordersApi from "../../repository/customer-order/orders.api";
import spinner from "@/assets/images/loading.gif";

const STATE_LABEL = "stateLabel";

export default {
  name: "HistoryDetailsNode",
  props: {
    /**
     * Node information.
     * @type HistoryDetailNode
     */
    node: { type: Object, required: true }
  },
  computed: {
    orderIdentifier() {
      return this.$route.params.orderIdentifier;
    },
    label() {
      const labelMappings = {
        root: {
          comment: "specialNote"
        },
        container: {
          type: "containerType",
          spi: "pcsIdentifier"
        },
        reefer: {
          setPoint: "reeferTemperature"
        },
        oog: {
          height: "oogHeight",
          width: "oogWidth",
          length: "oogLength"
        },
        indicator: {
          isOog: "OOG",
          isReefer: "reefer",
          isHazardous: "hazardous"
        },
        voyage: {
          company: "shippingCompany"
        }
      };

      if (this.node.label === "incidents") return "incidents.title";
      if (this.node.label === "state") return STATE_LABEL;
      if (this.node.label === "hazardousClasses") return "hazardous";
      if (this.node.label === "pictures") return "incidents.pictures";

      const events = [
        "DeliveryExport",
        "DeliveryImport",
        "EmptyReleaseExport",
        "EmptyReleaseImport",
        "EmptyReturnImport",
        "LadingExport",
        "LadingImport",
        "PositioningExport"
      ];

      if (events.includes(this.node.label)) return "event." + this.node.label;

      const parentMap = labelMappings[this.node.parentLabel];

      // If a mapping exists for the current parentLabel and node.label, return the mapped value
      return parentMap && parentMap[this.node.label]
        ? parentMap[this.node.label]
        : this.node.label;
    },
    itemClasses() {
      return {
        item: this.node.type != "primitive"
      };
    },
    iconPack() {
      return "fas";
    },
    iconName() {
      const iconMap = {
        Edited: "edit",
        Added: "plus",
        Removed: "trash"
      };
      return this.node.action && this.node.action instanceof Array
        ? iconMap[this.node.action[0]] || ""
        : iconMap[this.node.action] || "";
    },
    tagType() {
      const typeMap = {
        Edited: "is-primary",
        Added: "is-success",
        Removed: "is-danger"
      };
      return this.node.action && this.node.action instanceof Array
        ? typeMap[this.node.action[0]] || ""
        : typeMap[this.node.action] || "";
    },
    isEdited() {
      return (
        this.node?.action === "Edited" &&
        (this.node.newValue || this.node.oldValue)
      );
    },
    isAdded() {
      return this.node?.action === "Added";
    },
    isRemoved() {
      return this.node?.action === "Removed";
    },
    isDuplicateData() {
      return this.node?.newValue === this.node?.parentLabel;
    },
    isNotFilename() {
      return this.node?.label !== "fileName";
    },
    canShowLabel() {
      // label of sealNumbers' element will be its index so we won't display this index
      return this.isNotFilename && this.node.parentLabel != "sealNumbers";
    }
  },
  data() {
    return {
      oldImage: null,
      newImage: null,
      isLoadingOldImage: false,
      isLoadingNewImage: false,
      spinner
    };
  },
  async mounted() {
    try {
      if (this.node.label === "fileName") {
        this.isLoadingOldImage = true;
        this.isLoadingNewImage = true;
        if (this.node.oldValue) {
          const oldImageBase64 = await ordersApi.getIncidentPictureByFileName(
            this.orderIdentifier,
            this.node.oldValue
          );
          this.oldImage =
            "data:image/jpeg;base64," + oldImageBase64.data.content;
        }
        if (this.node.newValue) {
          const newImageBase64 = await ordersApi.getIncidentPictureByFileName(
            this.orderIdentifier,
            this.node.newValue
          );
          this.newImage =
            "data:image/jpeg;base64," + newImageBase64.data.content;
        }
      }
    } finally {
      this.isLoadingOldImage = false;
      this.isLoadingNewImage = false;
    }
  },
  methods: {
    /**
     * Format value.
     * @param {string} value
     */
    formatValue(value) {
      if (this.isDate(value)) {
        return this.formatDate(value);
      }

      if (this.node.fieldType === "Number") {
        // Replace decimal point comma (if any) with dot for consistent number conversion
        if (!value) return null;
        const normalizedValue = value?.replace(",", ".");
        const { locale } = this.$i18n;
        return Number(normalizedValue).toLocaleString(locale);
      }

      if (this.node.fieldType === "Boolean") {
        return this.$t(value);
      }

      const incidentTypes = ["NonCompliantQuantity", "Damage", "Other", "Late"];
      if (this.label == "type" && incidentTypes.includes(value))
        return this.$t("incidents.type." + value);

      const eventStates = ["InProgress", "OK", "KO"];
      if (this.label == STATE_LABEL && eventStates.includes(value))
        return this.$t(
          "state." +
            (value == "OK" ? "ok" : value == "KO" ? "ko" : "inProgress")
        );

      return value;
    },
    /**
     * Check a string is valid with the date format
     * @param {string} value
     */
    isDate(value) {
      // Check if it's a valid date format
      return moment(
        value,
        ["YYYY-MM-DDTHH:mm:ss.SSSSSSZ", "YYYY-MM-DDTHH:mm:ssZ"],
        true
      ).isValid();
    },
    /**
     * Format a date with given format
     * @param {string} date
     */
    formatDate(date) {
      return this.$d(new Date(date), "shortWithoutTimeZone", this.$i18n.locale);
    },
    /**
     * Check a string can be a number
     * @param {string} value
     */
    isNumeric(value) {
      return /^-?\d+([.,]\d+)?$/.test(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables.scss";

.item {
  border: 1px #e2e2e2 solid;
  border-radius: 8px;
  padding: 16px;
  margin-top: $margin-history-node;
  position: relative;
}

.label {
  position: absolute;
  top: -12px;
  left: 16px;
  background: #fff;
}

.font-bold {
  font-weight: bold;
}

.type-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 6px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
}

.edit-wrapper {
  display: flex;
  flex-direction: row;
}

.is-success {
  background: #3caea3 !important;
}

@media screen and (max-width: 768px) {
  .edit-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .item {
    padding: 12px;
    margin-top: 16px;
  }
  .type-wrapper {
    margin-top: 8px;
  }
}

.picture-content {
  width: 180px;
  height: auto;
  margin-top: 8px;
}

.image-container {
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: center;
}
</style>
