import { render, staticRenderFns } from "./NewContainers.vue?vue&type=template&id=105d1bfa&"
import script from "./NewContainers.vue?vue&type=script&lang=js&"
export * from "./NewContainers.vue?vue&type=script&lang=js&"
import style0 from "./NewContainers.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/container-info.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fcontainer-info%2FNewContainers.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/container-info.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fcustomer-order%2Fcontainer-info%2FNewContainers.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports