<i18n src="@/i18n/locales/voyage-info.en.json"></i18n>
<i18n src="@/i18n/locales/voyage-info.fr.json"></i18n>

<template>
  <b-field
    :label="$t('voyage')"
    label-position="on-border"
    class="mt-5 large-label voyage-info"
    custom-class="transparent-label"
  >
    <div v-if="showVoyage" class="box">
      <div class="mt-1 is-flex is-justify-content-space-between">
        <div class="columns is-expanded">
          <!-- Maritime voyage -->
          <div class="column is-one-fifth">
            <InputWithValidation
              id="means-name"
              rules="max:384"
              :label="$t('meansName')"
              validation-name="meansName"
              v-model="meansName"
              @blur="onVoyageInformationChange('meansName', meansName)"
            />
          </div>

          <!-- Freight agent -->
          <div class="column is-one-fifth">
            <InputWithValidation
              id="freight-agent"
              rules="max:256"
              :label="$t('freightAgent')"
              validation-name="freightAgent"
              v-model="freightAgent"
              @blur="onVoyageInformationChange('freightAgent', freightAgent)"
            />
          </div>

          <!-- Shipping company -->
          <div class="column is-one-fifth">
            <InputWithValidation
              id="shipping-company"
              rules="max:256"
              :label="$t('shippingCompany')"
              validation-name="shippingCompany"
              v-model="shippingCompany"
              @blur="
                onVoyageInformationChange('shippingCompany', shippingCompany)
              "
            />
          </div>

          <!-- ETA date is shown if order is import -->
          <div
            v-if="customsFlag === 'import'"
            class="column is-one-fifth-desktop"
          >
            <b-field :label="$t('eta')" label-position="on-border">
              <DateTimePicker
                id="eta"
                v-model="eta"
                @input="onVoyageInformationChange('eta', eta)"
              />
            </b-field>
          </div>

          <!-- ETD date is shown if order is export -->
          <div
            v-if="customsFlag === 'export'"
            class="column is-one-fifth-desktop"
          >
            <ValidationProvider
              :rules="isEditingOrder ? '' : 'date_eta_etd'"
              v-slot="{ errors }"
              name="etd"
            >
              <b-field :label="$t('etd')" label-position="on-border">
                <DateTimePicker
                  v-model="etd"
                  @input="onVoyageInformationChange('etd', etd)"
                />
                <template #message>
                  <p class="has-text-danger">
                    {{ errors[0] }}
                  </p>
                </template>
              </b-field>
            </ValidationProvider>
          </div>
        </div>

        <b-icon
          pack="fas"
          icon="chevron-up"
          class="is-size-6 is-clickable ml-4"
          @click.native="showVoyage = false"
        />
      </div>

      <div v-if="customsFlag === 'export'" class="columns">
        <!-- Customs cut-off time -->
        <div class="column">
          <ValidationProvider
            :rules="isEditingOrder ? '' : 'date_from_today'"
            v-slot="{ errors }"
            name="closingCustoms"
          >
            <b-field :label="$t('closingCustoms')" label-position="on-border">
              <DateTimePicker
                v-model="closingCustoms"
                :position="'left'"
                @input="
                  onVoyageInformationChange('closingCustoms', closingCustoms)
                "
              />
              <template #message>
                <p class="has-text-danger">
                  {{ errors[0] }}
                </p>
              </template>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Dangerous cut-off time -->
        <div class="column">
          <ValidationProvider
            :rules="isEditingOrder ? '' : 'date_from_today'"
            v-slot="{ errors }"
            name="closingDangerous"
          >
            <b-field :label="$t('closingDangerous')" label-position="on-border">
              <DateTimePicker
                v-model="closingDangerous"
                @input="
                  onVoyageInformationChange(
                    'closingDangerous',
                    closingDangerous
                  )
                "
              />
              <template #message>
                <p class="has-text-danger">
                  {{ errors[0] }}
                </p>
              </template>
            </b-field>
          </ValidationProvider>
        </div>

        <!-- Delivery cut-off time -->
        <div class="column">
          <ValidationProvider
            :rules="isEditingOrder ? '' : 'date_from_today'"
            v-slot="{ errors }"
            name="closingDelivery"
          >
            <b-field :label="$t('closingDelivery')" label-position="on-border">
              <DateTimePicker
                v-model="closingDelivery"
                @input="
                  onVoyageInformationChange('closingDelivery', closingDelivery)
                "
              />
              <template #message>
                <p class="has-text-danger">
                  {{ errors[0] }}
                </p>
              </template>
            </b-field>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div v-else class="is-flex is-justify-content-flex-end expand-arrow mr-4">
      <b-icon
        pack="fas"
        icon="chevron-down"
        class="is-size-6 is-clickable"
        @click.native="showVoyage = true"
      />
    </div>
  </b-field>
</template>

<script>
import Vue from "vue";

import DateTimePicker from "@/components/common/DateTimePicker.vue";
import InputWithValidation from "@/components/common/InputWithValidation.vue";

export default {
  name: "VoyageInfo",

  components: { DateTimePicker, InputWithValidation },

  props: {
    // propVoyage will have value when it's used for editing order purpose
    propVoyage: {
      type: Object
    },

    customsFlag: {
      type: String,
      default: "import",
      required: true
    },

    // if all the elements in containers array is deleted, we reset the voyageId to re-check new prefilled containers
    resetVoyageId: {
      type: Boolean,
      default: true,
      required: true
    },

    // this variable is used to track if there's only one container tab in case user changes to a different container code in this tab
    hasOneContainer: {
      type: Boolean,
      default: true,
      required: true
    }
  },

  data() {
    return {
      // showVoyage if there's any voyage information even though customsFlag is export or not
      showVoyage: this.propVoyage !== undefined,
      isEditingOrder: this.$route.params?.orderIdentifier !== undefined,
      voyageId: null,

      voyage: this.propVoyage ? this.propVoyage : {},

      meansName: this.propVoyage?.meansName,
      freightAgent: this.propVoyage?.freightAgent,
      shippingCompany: this.propVoyage?.shippingCompany,
      eta: this.propVoyage?.eta ? new Date(this.propVoyage.eta) : null,
      etd: this.propVoyage?.etd ? new Date(this.propVoyage.etd) : null,
      closingCustoms: this.propVoyage?.closingCustoms
        ? new Date(this.propVoyage.closingCustoms)
        : null,
      closingDangerous: this.propVoyage?.closingDangerous
        ? new Date(this.propVoyage.closingDangerous)
        : null,
      closingDelivery: this.propVoyage?.closingDelivery
        ? new Date(this.propVoyage.closingDelivery)
        : null
    };
  },

  watch: {
    customsFlag(newVal) {
      if (newVal == "export") this.showVoyage = true;
    },

    "$root.prefillContainer"(newVal) {
      // we ONLY set voyage info for the first prefill container or if there's the same voyageId among multiple prefill containers
      let prefillVoyage = newVal?.voyage;
      if (prefillVoyage?.voyageId)
        if (!this.voyageId || this.hasOneContainer) {
          // if there's no prefill container before or all containers are deleted (this.voyageId == null) or if user changes the container code in one existing container tab (this.hasOneContainer == true)
          this.meansName = prefillVoyage?.meansName;
          this.freightAgent = prefillVoyage?.freightAgent;

          // a handling-unit from tracing may have both eta and etd value no matter what the customsFlag is,
          // however, we don't need etd value for import order and eta value for export order
          if (this.customsFlag == "import") {
            this.eta = prefillVoyage?.eta ? new Date(prefillVoyage.eta) : null;
          } else {
            this.etd = prefillVoyage?.etd ? new Date(prefillVoyage.etd) : null;
          }

          this.showVoyage = true;
          this.voyageId = prefillVoyage.voyageId;
        } else if (this.voyageId == prefillVoyage?.voyageId) return;
        else {
          // if there're different voyageId from multiple containers, we won't keep any value
          this.meansName = null;
          this.freightAgent = null;
          this.eta = null;
          this.etd = null;
        }

      this.onVoyageInformationChange("meansName", this.meansName);
      this.onVoyageInformationChange("freightAgent", this.freightAgent);
      this.onVoyageInformationChange("eta", this.eta);
      this.onVoyageInformationChange("etd", this.etd);
    },

    resetVoyageId(newVal) {
      if (newVal) this.voyageId = null;
    }
  },

  methods: {
    onVoyageInformationChange(field, value) {
      Vue.set(this.voyage, field, value);
      this.$emit("set-order-information", "voyage", this.voyage);
    }
  }
};
</script>

<style>
.field.large-label > label {
  font-size: 1rem !important;
}

.voyage-info .box {
  box-shadow: 0 0, 0 0 !important;
  border-color: #d3d0d0;
  padding: 1.25rem 1rem 0.25rem 1rem;
}

.voyage-info .box .columns {
  margin-bottom: 0;
}

.expand-arrow {
  border-bottom: 1px solid #d3d0d0;
}
</style>
