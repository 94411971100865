import { render, staticRenderFns } from "./HistoryDetailsDialog.vue?vue&type=template&id=68be03a6&scoped=true&"
import script from "./HistoryDetailsDialog.vue?vue&type=script&lang=js&"
export * from "./HistoryDetailsDialog.vue?vue&type=script&lang=js&"
import style0 from "./HistoryDetailsDialog.vue?vue&type=style&index=0&id=68be03a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68be03a6",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/locales/customer-order.en.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FHistoryDetailsDialog.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "@/i18n/locales/customer-order.fr.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fsuper-viewer%2FHistoryDetailsDialog.vue"
if (typeof block1 === 'function') block1(component)

export default component.exports