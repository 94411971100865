<template>
  <b-datetimepicker
    v-model="dateTime"
    :locale="$i18n.locale"
    icon-pack="fas"
    icon="calendar-alt"
    :icon-right="dateTime && !isDisabled ? 'close-circle' : ''"
    :icon-right-clickable="!isDisabled"
    @icon-right-click="clearDateTime"
    trap-focus
    editable
    :disabled="isDisabled"
    :mobile-native="false"
    :datetime-parser="dateTimeParser"
    :class="datePickerClass"
  />
</template>

<script>
import moment from "moment";

export default {
  name: "DateTimePicker",
  props: {
    value: Date,

    /**
     * Determine if the edition is disable
     */
    isDisabled: {
      type: Boolean,
      default: false
    },

    position: {
      type: String,
      default: "right"
    }
  },

  data() {
    return {
      dateTime: this.value ?? null
    };
  },

  computed: {
    datePickerClass() {
      return "datepicker-" + this.position;
    }
  },

  watch: {
    dateTime: function(newVal, oldVal) {
      if (newVal?.getTime() !== oldVal?.getTime()) {
        this.$emit("input", newVal);
      }
    },
    value: function(newVal) {
      this.dateTime = newVal;
    }
  },
  methods: {
    clearDateTime() {
      this.dateTime = null;
    },

    isValidDate(date, format) {
      return moment(date, format).isValid();
    },

    dateTimeParser(date) {
      if (this.$i18n.locale == "fr") {
        if (this.isValidDate(date, "DD/MM/YYYY HH:mm"))
          return moment(date, "DD/MM/YYYY HH:mm")
            .locale("fr")
            .toDate();
      }
      if (this.$i18n.locale == "en") {
        if (this.isValidDate(date, "MM/DD/YYYY, HH:mm"))
          return moment(date, "MM/DD/YYYY, HH:mm")
            .locale("en")
            .toDate();
      }
    }
  },
  created: function() {
    this.$emit("input", this.dateTime);
  }
};
</script>

<style scoped></style>
